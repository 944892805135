.chat-header {
    @extend .flex-between;
    padding-inline: $--paddding-m;
    background-color: $--color-darker-white;
    height: 100%;
    &__right {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        &-avatar {
            object-fit: contain;
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
        }
        &-title {
            @include media-prop(font-size, 1.6rem, 1.4rem, 1.2rem);
            margin-right: $--margin-s;
        }
        &-status {
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
            margin-right: $--margin-s;
            background-color: #808080;
            border: 3px solid $--color-dark-white;
            &.active {
                background-color: #6ec531;
            }
        }
    }
    // admin accept and reject buttons
    &__left {
        & .btn:last-child {
            margin-right: $--margin-s;
        }
    }
}
// Drop Down menu
.dropdown_info {
    position: absolute;
    background-color: white;
    overflow: hidden;
    height: 0;
    z-index: 10;
    &.active {
        width: max-content;
        padding: $--paddding-l;
        height: auto;
    }
    &__item {
        color: $--color-black;
        @extend .typo--s;
        & span {
            color: $--color-gray;
        }
    }
}
