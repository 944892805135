.home {
  &-counter {
    display: grid;
    @include media-prop(
      grid-template-columns,
      repeat(3, 1fr),
      repeat(1, 1fr),
      1fr
    );
    gap: $--margin-m;
    place-items: center;
    padding: $--margin-xl;
    width: 100%;
    background-color: $--color-darker-white;
    margin-bottom: $--margin-m;
  }
  &-value {
    background-color: $--color-darker-white;
    width: 100%;
  }

  &-clients {
    @extend .section-top-margin;
    width: 100%;
    min-height: 35vh;
  }

  &-contact {
    @extend .section-top-margin;
    background-color: $--color-black;
    margin-bottom: $--margin-l;
    display: grid;
    @include media-prop(grid-template-columns, repeat(2, 1fr), 1fr, 1fr);
    padding: $--paddding-l;
    @include media-prop(width, 80%, 100%, 100%);

    margin-inline: auto;

    &-left {
      &__items {
        display: grid;
        @include media-prop(grid-template-columns, repeat(3, 1fr), 1fr, 1fr);
        gap: 2rem;

        &-item {
          @include media-prop(
            margin-right,
            $--margin-l,
            $--margin-m,
            $--margin-s
          );
          text-align: center;

          &__icon {
            width: 3em;
            margin: auto;
          }

          &__title {
            color: $--color-white;
            @extend .typo--l;
            @extend .typo--regular;
            margin-bottom: $--margin-s;
          }

          &__text {
            color: $--color-white;
            font-size: 1.2rem;
            @extend .typo--bold;
          }
        }
      }
    }

    &-right {
      @include media-prop(text-align, start, center, center);
      @include media-prop(margin-bottom, "nil", $--margin-m, $--margin-m);
      &__title {
        @extend .typo--2xl;
        @extend .typo--bold;
        color: $--color-white;

        & span {
          @extend .gradient-text-primary;
          margin-bottom: $--margin-s;
        }
      }

      &__description {
        color: $--color-white;
        @extend .typo--l;
        @extend .typo--light;
        @extend .textWidth;
        line-height: 1.3;
      }
    }
  }
}
