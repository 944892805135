.products {
  background: $--dark-gradient;
  text-align: center;
  @include media-prop(padding-inline, 7rem, 4.2rem, 3.2rem);
  padding-top: $--paddding-m;

  &__title {
    margin-bottom: $--margin-m;
    &-top {
      @extend .typo--2xl;
      @extend .typo--sbold;
      color: $--color-primary;
    }
    &-bottom {
      @extend .typo--l;
      @extend .textWidth;
      @extend .typo--light;
      line-height: 1.2;
      color: $--color-white;
      margin: auto;
      margin-bottom: $--margin-m;
    }
  }

  &__products {
    display: grid;
    @include media-prop(grid-template-columns, repeat(3, minmax(250px, 1fr)), minmax(250px, 1fr), minmax(250px, 1fr));
    place-items: center;
    gap: 2rem;
    margin: auto;
    align-items: stretch;
    max-width: 1150px;
  }
}
