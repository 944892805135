.admin-topnav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $--paddding-s $--paddding-m;
    @include media-prop(padding-right, 24rem, 21rem, 21rem);
    background-color: $--dark-gradient;
    border-bottom: 1px solid $--color-gray;
    position: absolute;
    background-color: $--color-black-light;
    width: 100%;
    min-height: 6rem;
    z-index: 100;
    &__user {
        &-avatar {
            border-radius: 50%;
            width: 4rem;
            height: 4rem;
            object-fit: cover;
        }
    }
    &__left {
        &__actions {
            margin-left: $--margin-l;
            display: flex;
            align-items: center;
            &__item {
                cursor: pointer;
                &:hover &-icon {
                    color: $--color-blue;
                }
                margin-left: $--margin-l;
                position: relative;
                @extend .typo--l;
                &-count {
                    position: absolute;
                    top: -1rem;
                    right: -2rem;
                    background-color: red;
                    border-radius: 50%;
                    width: 1.8rem;
                    height: 1.8rem;
                    color: $--color-white;
                    @extend .flex-center;
                    font-size: 0.9rem;
                }
                &-icon {
                    color: $--color-gray;
                    transition: $--transition;
                    @extend .typo--xl;
                }
            }
        }
    }
}
