.sidebar__item {
    width: 100%;
    padding: $--paddding-s;
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;
    position: relative;

    &.active {
        background-color: $--color-primary;
        & .sidebar__item-icon {
            color: $--color-black;
        }
    }

    &-icon {
        margin-left: $--margin-s;
        color: $--color-primary;
        @extend .typo--m;
    }
    &-link {
        color: $--color-white;
        @extend .typo--l;
        @include media-prop(display, flex, none, none);
    }
    &:hover {
        background-color: $--color-primary;
    }
    &:hover .sidebar__item-icon {
        color: $--color-black;
    }
}
