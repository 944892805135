.counter {
    @extend .flex-center;
    flex-direction: column;
    border: 2px solid $--color-primary;
    transform: rotate(45deg);
    width: fit-content;
    min-width: 13rem;
    min-height: 13rem;
    @include media-prop(margin-bottom, "nil", $--margin-xl, $--margin-xl);
    &:nth-child(2n) {
        @include media-prop(margin-right, "nil", $--margin-2xl, "nil");
    }
    &__content {
        transform: rotate(-45deg);
        text-align: center;
    }
    &__icon {
        &-icon {
            @extend .typo--2xl;
            color: $--color-primary;
        }
        &-title {
            color: $--color-black;
            @extend .typo--m;
            @extend .typo--bold;
        }
    }
    &__value {
        color: $--color-black;
        @extend .typo--s;
        font-weight: bold;
    }
}
