.instructions {
    &__card {
        background-color: $--color-darker-white;
        display: inline-block;
        padding: $--paddding-m;
        text-align: center;
        transition: $--transition;

        &:hover {
            box-shadow: $--box-shadow;
            -webkit-box-shadow: $--webkit-box-shadow;
        }
        &-icon {
            @extend .typo--2xl;
            color: $--color-primary;
        }
        &-title {
            @extend .typo--l;
            color: $--color-black-light;
            @extend .textWidth;
            margin: 0.5rem auto 0;

            line-height: 1.2;
        }
        &-description {
            color: $--color-gray;
            @extend .typo--m;
        }
    }
    // item form the instructions
    &__item {
        margin-bottom: $--margin-m;
        @include media-prop(display, flex, block, block);
        align-items: center;
        padding-inline: $--paddding-m;
        &-img {
            object-fit: contain;
            max-width: 20rem;
            margin: auto;
        }
        &-content {
            margin-right: $--margin-m;
            text-align: start;
            &__title {
                color: $--color-primary;
                @extend .typo--l;
            }
            &__description {
                @extend .typo--m;
                color: $--color-white;
                line-height: 1.2;
                @extend .textWidth;
                line-break: auto;
            }
            &__actions {
                margin-top: $--margin-s;
                & button {
                    margin-right: $--margin-s;
                }
            }
        }
    }
}

.card-wrapper {
    display: grid;
    @include media-prop(grid-template-columns, repeat(2, 1fr), 1fr, 1fr);
    gap: 2rem;
    margin-bottom: $--margin-m;
}
