.accordion {
    border: 1px solid $--color-darker-white;
    background-color: #fff;
    border-radius: 1rem;
    @include media-prop(max-width, 80rem, 73.6rem, 34.3rem);
    @include media-prop(min-height, 9.2rem, 9.2rem, 5.6rem);
    @include media-prop(padding, 1.2rem, 1rem, 1rem);
    margin: 0 auto 1.6rem;
    cursor: pointer;
    width: 80%;

    &-top {
        @extend .flex-center;
        padding-bottom: 1.6rem;
        // border: 1px solid $--color-black;
        padding: $--paddding-s;
        border-radius: 0.5rem;
        &__title {
            @extend .flex-between;
            width: 100%;

            &__question {
                display: flex;
                align-items: center;
                font-weight: 500;
                color: $--color-black;
                max-width: 80%;

                &-counter {
                    margin-inline: 1rem;
                    font-size: 1.8rem;
                }
                &-q {
                    @extend .typo--l;
                }
            }
            &__icon {
                @extend .typo--m;
                color: $--color-black;
            }
        }
    }

    &-answer {
        font-size: 1.6rem;
        color: $--color-black;
        font-weight: 300;
        line-height: 1.6;
        max-height: 0;
        white-space: pre-wrap;
        transition: $--transition;
        overflow: hidden;
        margin-top: $--margin-s;
        &.active {
            // border: 1px solid $--color-black;

            width: auto;
            max-height: max-content;
            transition: $--transition;
        }
    }
}
