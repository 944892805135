.pagenot-found {
  &__error {
    font-size: 18rem;
    text-align: center;
    @extend .gradient-text-primary;
  }
  &__title {
    @extend .typo--xl;
    text-align: center;
    margin-bottom: $--margin-l;
    color: $--color-white;
  }
  &__instructions {
    color: $--color-white;
    @extend .typo--l;
    font-weight: bold;
    text-align: center;
  }
  &__link {
    @extend .typo--xl;
    text-align: center;
    @extend .gradient-text-primary;
  }
}
