.ourClients {
  &-description {
    color: $--color-darker-white;
    @extend .typo--m;
    @extend .typo--light;
    @include media-prop(width, 50%, 80%, 80%);
    line-height: 1.4;
    margin: auto;
  }

  &__imgs {
    @extend .flex-center;
    gap: $--margin-s;
    @include media-prop(max-width, 60%, 100%, 100%);
    padding-inline: $--paddding-l;
    margin: $--margin-l auto 0;

    &__img {
      object-fit: contain;
      width: 10rem;
      height: 10rem;
      padding: $--paddding-xs2;

      &:hover {
        filter: drop-shadow(5px 5px 10px #fff);
        opacity: 1;
      }
    }
  }
}
