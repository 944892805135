.addProduct {
    @extend .add-user;
    &__upload {
        max-width: 20rem;
        margin: auto;
        width: fit-content;
        &__image {
            cursor: pointer;
            display: flex;
            flex-direction: column;

            &-img {
                border-radius: 1rem;
            }
            &__title {
                margin-block: $--margin-s;
                display: flex;
                align-items: center;
                justify-content: center;

                @extend .typo--m;
                &-text {
                    color: $--color-white;
                    margin-right: $--margin-s;
                }
            }
            &-icon {
                @extend .typo--l;
                color: $--color-primary;
            }
        }
        &__input {
            display: none;
        }
    }
}
