.typo {
  font-family: $--font-family;
  &--bold {
    font-weight: 700;
  }
  &--sbold {
    font-weight: 600;
  }
  &--medium {
    font-size: 500;
  }
  &--regular {
    font-weight: 400;
  }
  &--light {
    font-weight: 300;
  }
  &--xs {
    @include media-prop(font-size, 1.1rem, 0.9rem, 0.8rem);
  }

  &--s {
    font-size: 1.4rem;
  }

  &--m {
    @include media-prop(font-size, 1.4rem, 1.2rem, 1.2rem);
  }

  &--l {
    @include media-prop(font-size, 1.8rem, 1.4rem, 1.2rem);
  }
  &--l1 {
    @include media-prop(font-size, 2rem, 1.8rem, 1.4rem);
  }
  &--xl {
    @include media-prop(font-size, 2.2rem, 2rem, 1.8rem);
  }
  &--2xl {
    @include media-prop(font-size, 2.8rem, 2.4rem, 2rem);
  }

  &--extra-xl {
    font-size: calc(15px + 4vmin);
  }
  
}

.error{
  color: $--color-danger;
}