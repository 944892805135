.btn {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  outline: 1px solid transparent;
  font-family: $--font-family;
  text-align: center;
  white-space: nowrap;
  &:is(:active, :hover) {
    transform: scale(0.9);
    transition: 250ms;
  }
  &--primary {
    background-color: $--color-primary;
    color: $--color-black;
    transition: $--transition;
    &:is(:hover, :active, :focus) {
      background-color: $--color-secondary;
      box-shadow: $--box-shadow;
      -webkit-box-shadow: $--webkit-box-shadow;
    }
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  &--secondary {
    &-white {
      color: $--color-white;
    }
    color: $--color-black-light;
    border-color: $--color-primary;
    transition: $--transition;
    &:is(:hover, :active, :focus) {
      background-color: $--color-secondary;
      color: $--color-black-light;
      box-shadow: $--box-shadow;
      -webkit-box-shadow: $--webkit-box-shadow;
    }
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  &--danger {
    background-color: $--color-danger;
    color: $--color-white;
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  &--success {
    background-color: $--color-green;
    color: $--color-white;
    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  &--s {
    @include media-prop(padding, 0.1rem 0.5rem, 0.1rem 0.3rem, 0.1rem 0.3rem);
    border-radius: 0.3rem;
    @extend .typo--xs;
    @extend .typo--regular;
  }
  &--m {
    @include media-prop(padding, 0.5rem 1rem, 0.5rem 0.9rem, 0.3rem 0.9rem);
    border-radius: 0.2rem;
    @extend .typo--m;
    @extend .typo--regular;
  }
  &--l {
    @include media-prop(padding, 0.5rem 1.5rem, 0.5rem 1.5rem, 0.5rem 1rem);
    border-radius: 0.2rem;
    @extend .typo--l;
    @extend .typo--regular;
  }
  &--xl {
    padding: 1.5rem 2.2rem;
    @include media-prop(font-size, 2rem, 1.9rem, 1.8rem);
    border-radius: $--paddding-s;
  }
  &--rounded {
    border-radius: 50%;
  }
}
