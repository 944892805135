.footer-list {
  &__container {
    &__list {
      display: flex;
      &__item {
        &__link {
          padding-inline: $--paddding-s;
          color: $--color-white;
          background-color: $--color-black;
          margin-right: 0.5rem;
          border-radius: 0.5rem;
          @extend .typo--light;
          font-size: 1.4rem;
          margin-bottom: $--margin-s;
          border-bottom: 1px solid transparent;
          transition: $--transition;
          &:hover {
            color: $--color-white;
            background-color: $--color-primary;
            border-bottom-color: $--color-white;
          }
        }
      }
    }
  }
}
