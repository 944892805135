.tickets {
    padding-inline: $--paddding-m;

    &__header {
        margin-block: $--margin-l;
    }
    &__content {
        display: grid;
        @include media-prop(grid-template-columns, repeat(3, 1fr), 1fr, 1fr);
        gap: 1rem;
        @include media-prop(width, 70%, 100%, 100%);
        margin: $--margin-l auto;
    }
}

.ticketStatus {
    padding: $--paddding-xs1 $--paddding-s;
    color: $--color-darker-white;
    border-radius: 0.5rem;
    width: 100%;
    @extend .typo--xs;
    text-align: center;

    &.solved {
        background-color: #28a745;
    }
    &.pending {
        background-color: $--color-gray;
        color: $--color-darker-white;
    }
    &.rejected {
        background-color: $--color-danger;
    }
}
.notfication {
    @extend .typo--s;
    color: $--color-white;
    width: 2rem;
    height: 2rem;
    background-color: $--color-danger;
    border-radius: 50%;
    @extend .flex-center;
    margin: auto;
}

.seen {
    color: $--color-white;
    background-color: #28a745;
    font-size: 16px;
    padding: 3px 10px;
}
