.chat {
    height: 100%;
    width: 100%;
    padding-top: 6.6rem;
    overflow: hidden;
    background-size: contain;

    &__left {
        height: 100%;
        width: 100%;
        &__header {
            height: 10vh;
        }
        &__chats {
            height: 70vh;
            background-size: cover;
            background-repeat: repeat;
            position: relative;
            max-height: 100%;
            overflow-y: scroll;
            padding-block: $--paddding-l;
        }
        &__footer {
            height: 10vh;
        }
    }
}
.no-messages {
    @extend .typo--m;
    padding: $--paddding-s;
}
