:root {
  // colors
  --color-primary: #e1c455;
  --color-secondary: rgb(241, 196, 80);
  --color-white: #f7f5f0;
  --color-dark-white: #e3e3e3;
  --color-darker-white: #eee;
  --color-black: #000;
  --color-black-light: #191a19;
  --color-gray: #808080;
  --sidenav-bg: #3d464d;
  --color-blue: #007ced;
  --color-danger: #ff0e0e;
  --color-green: #4bb543;
  --box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.47);
  --webkit-box-shadow: 2px 4px 10px rgba(201, 201, 201, 0.47);
  --dark-gradient: linear-gradient(
    90deg,
    rgb(4, 4, 4) 0%,
    rgba(14, 14, 14, 1) 35%,
    rgba(48, 45, 45, 1) 100%
  );
  --font-family: "Rubik", sans-serif;
  // paddings
  --paddding-xs1: 0.4rem;
  --paddding-xs2: 0.8rem;
  --paddding-s: 1.2rem;
  --paddding-m: 2.4rem;
  --paddding-l: 3.2rem;
  --paddding-xl: 4rem;
  --paddding-2xl: 4.2rem;

  // margins
  --margin-xs1: 0.4rem;
  --margin-xs2: 0.8rem;
  --margin-s: 1.2rem;
  --margin-m: 2.4rem;
  --margin-l: 3.2rem;
  --margin-xl: 4rem;
  --margin-2xl: 4.2rem;

  // fonts

  --font-size-xs: 1.2rem;
  --font-size-s: 1.4rem;
  --font-size-m: 1.6rem;
  --font-size-l: 1.8rem;
  --font-size-xl: 2.4rem;
  --font-size-2xl: 2.8rem;
  --font-size-3xl: 3rem;

  // transitions
  --transition: all 0.3s linear;
}
// colors
$--sidenav-bg: var(--sidenav-bg);
$--color-blue: var(--color-blue);
$--color-primary: var(--color-primary);
$--color-secondary: var(--color-secondary);
$--color-white: var(--color-white);
$--color-dark-white: var(--color-dark-white);
$--color-darker-white: var(--color-darker-white);
$--color-black: var(--color-black);
$--color-black-light: var(--color-black-light);
$--color-danger: var(--color-danger);
$--dark-gradient: var(--dark-gradient);
$--color-gray: var(--color-gray);
$--color-green: var(--color-green);
$--box-shadow: var(--box-shadow);
$--webkit-box-shadow: var(--webkit-box-shadow);
// paddings
$--paddding-xs1: var(--paddding-xs1);
$--paddding-xs2: var(--paddding-xs2);
$--paddding-s: var(--paddding-s);
$--paddding-m: var(--paddding-m);
$--paddding-l: var(--paddding-l);
$--paddding-xl: var(--paddding-xl);
$--paddding-2xl: var(--paddding-2xl);

// margins
$--margin-xs1: var(--margin-xs1);
$--margin-xs2: var(--margin-xs2);
$--margin-s: var(--margin-s);
$--margin-m: var(--margin-m);
$--margin-l: var(--margin-l);
$--margin-xl: var(--margin-xl);
$--margin-2xl: var(--margin-2xl);

// font size
$--font-family: var(--font-family);
$--font-size-xs: var(--font-size-xs);
$--font-size-s: var(--font-size-s);
$--font-size-m: var(--font-size-m);
$--font-size-l: var(--font-size-l);
$--font-size-xl: var(--font-size-xl);
$--font-size-2xl: var(--font-size-2xl);
$--font-size-3xl: var(--font-size-3xl);

// transitions
$--transition: var(--transition);
