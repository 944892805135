.client-chat {
    background-color: #fff;
    width: 100%;
    padding: $--paddding-s;
    margin-bottom: $--margin-xs1;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    &:hover {
        background-color: $--color-darker-white;
    }
    &__info {
        display: flex;
        align-items: center;
        &__avatar {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            object-fit: contain;
            background-color: #fff;
        }
        &__title {
            margin-right: $--margin-s;
            @extend .typo--s;
            font-weight: 500;
        }
    }
    &__lastchat {
        margin-top: $--margin-s;
    }
    &__time {
        align-self: flex-end;
        margin: 0.7rem 1rem;
    }
}
