.faq {
    @extend .flex-center;
    flex-direction: column;
    &__title {
        @extend .contact-title;
    }
    &__actions{
        font-size: 20rem;
        position: absolute;
    }
}
