.support-item {
    margin-bottom: $--margin-m;

    &__icon{
        @extend .typo--l; 
        color: $--color-primary;
        padding-left: $--paddding-s;
    }
    &__link {
        font-weight: bold;
        @extend .typo--l;
        position: relative;
        color: $--color-primary;
        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0.2rem;
            background-color: $--color-primary;
            bottom: 0;
            right: 0;
        }
        &:hover {
            color: $--color-primary;
            &:before {
                width: 100%;
                transition: $--transition;
            }
        }
    }
    &__description {
        color: $--color-darker-white;
        @extend .typo--m;
        @extend .textWidth;
    }
}
