.product-card {
  background-color: #f7f7f9;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1607843137254902);
  transition: 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  border-radius: 2.5rem;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    box-shadow: $--box-shadow;
    -webkit-box-shadow: $--webkit-box-shadow;
  }
  &__coupon {
    padding: $--paddding-m;
    background-color: purple;
    color: $--color-white;
    position: relative;
    width: fit-content;
    margin-right: $--margin-s;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: #fff;
    }
    &::before {
      left: -1rem;
    }
    &::after {
      right: -1rem;
    }
    &__text {
      font-weight: bold;
      @extend .typo--l;
      display: block;
    }
  }

  &__top {
    width: 100%;

    &-img {
      object-fit: cover;
      @include media-prop(width, 18rem, 16rem, 16rem);
      @include media-prop(height, 18rem, 16rem, 16rem);
      margin: auto;
      border-radius: 1.25rem;
    }
    &-title {
      margin-top: $--margin-m;
      @extend .typo--l;
      line-height: 1.2;
      font-weight: bold;
      text-align: start;
      padding-inline: $--paddding-s;
    }
  }
  &__body {
    padding-inline: $--paddding-s;
    margin-bottom: $--margin-s;
    &-title {
      text-align: right;
      @extend .typo--m;
      margin-bottom: $--margin-s;
    }
    &-description {
      font-weight: 400;
      padding-block: 2.4rem;
      @extend .typo--m;
      text-align: start;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__footer {
    padding-block: $--paddding-s;
    &__categories {
      display: flex;
      align-items: center;
      &__item {
        max-width: fit-content;
        background-color: $--color-white;
        margin-right: $--margin-s;

        &-link {
          color: $--color-primary;
          text-decoration: underline;
          font: bold;
          @extend .typo--s;
          &:hover {
            color: $--color-primary;
            transition: $--transition;
          }
        }
      }
    }
  }
  &__adminActions {
    width: 100%;
    margin-top: $--margin-s;
    & .btn {
      margin-left: 20px;
    }
  }
}
