.progressBar {
    background-color: #ddd;
    border-radius: 1rem;
    width: 100%;
    height: 2rem;
    position: relative;
    &__progress {
        position: absolute;
        z-index: 1;
        border-radius: inherit;
        height: 100%;
    }
    &__text {
        z-index: 2;
        position: relative;
        font-size: 1rem;
        font-weight: bold;
        display: block;
        text-align: center;
    }
}
