//google font
// @font-face {
//   font-family: cairo;
//   src: url("../Assets/FONTS/Cairo-VariableFont_slnt\,wght.ttf");
// }

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap");
//abstracts Mixins
@import "./abstracts/mixin";
@import "./abstracts/variables";
// base and roots

@import "./base/reset";
@import "./base/typography";

// components
@import "./components/navbarlink";
@import "./components/button";
@import "./components/counter";
@import "./components/social";
@import "./components/header";
@import "./components/value";
@import "./components/ourClients";
@import "./components/productcard";
@import "./components/products";
@import "./components/contactform";
@import "./components/contactitem";
@import "./components/footerlist";
@import "./components/supportLinkItem";
@import "./components/chatFooter";
@import "./components/clientChat";
@import "./components/chatheader";
@import "./components/chatbubble";
@import "./components/accordion";
@import "./components/customInput";
@import "./components/formwrapper";
@import "./components/customSelect";
@import "./components/dataTabel";

//admin Components
@import "./components/adminSideBarItem";
@import "./components/chart";
@import "./components/widget";
@import "./components/featured";
@import "./components/featured";
@import "./components/dataTabel";

//layouts
@import "./layouts/footer";
@import "./layouts/FloatSideBtn";
@import "./layouts/multiStepForm";
@import "./layouts/companyForm";
@import "./layouts/personalForm";
@import "./layouts/skilliton";
@import "./layouts/ticketLayout";
@import "./layouts/customModal.scss";
// adminpage layouts
@import "./layouts/adminLayout";
@import "./layouts/adminTopNav.scss";
@import "./layouts/adminSideBar";

//pages
@import "./page/home";
@import "./page/contact";
@import "./page/product";
@import "./page/notfound";
@import "./page/login";
@import "./page/support";
@import "./page/chat";
@import "./page/faq";
@import "./page/instructions";
// admin pages
@import "./page/adminHome";
@import "./page/addProduct";
@import "./page/single";
@import "./page/addUser";

// themes

// shame
.loading {
  opacity: 0.5;
  user-select: none;
  overflow: hidden;
  & * {
    pointer-events: none;
    cursor: no-drop;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
