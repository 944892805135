.admin-home {
    &__charts {
        display: flex;
        @include media-prop(flex-direction, row, column, column);
        gap: $--paddding-m;
        margin-top: $--margin-m;
        padding-inline: $--paddding-s;
        max-width: 100vw;
        padding-top: $--paddding-s;
    }
}
.admin-faq {
    @extend .add-user;
}

.select-opt {
    font-size: 1.6rem !important;
}


