@tailwind base;
@tailwind components;
@tailwind utilities;

/* Buttons */
.btn {
  @apply transition-all duration-200;
}

.btn--primary {
  @apply bg-color-secondary text-black text-center rounded;
  &:is(:hover, :active) {
    box-shadow: var(--box-shadow);
    -webkit-box-shadow: var(--webkit-box-shadow);
  }
}

/* Social Icons  */
.social {
  @apply flex items-center gap-9 justify-center px-4;
}
.social-item {
  @apply bg-black rounded-full flex justify-center items-center w-16 h-16 p-3;
  &:hover.social-item__icon {
    @apply hover:text-warmGray-300;
  }
}
.social-item__icon {
  @apply transition-all duration-200 hover:text-warmGray-300 text-color-secondary text-4xl;
}

/* General Styles  */
.container {
  @apply mx-auto px-[1rem];
}

/* navbar Styles */
.navbar {
  @apply flex justify-between items-center min-h-[3rem] fixed w-full top-0 bg-black px-5 py-2 shadow-sm shadow-gray-800 z-10;
}

.navbar-left {
  @apply flex items-center z-[20];
}

.navbar-left__logo {
  @apply w-[5rem] lg:w-[7rem] object-contain;
}

.navbar-left__menu {
  @apply hidden lg:flex gap-x-5;
}

.navbar-right__mobile-menu {
  @apply flex lg:hidden text-white text-4xl ml-2 hover:text-color-primary active:text-color-primary;
}

.navbar-right__social {
  @apply hidden lg:flex;
}

.navbarItem-link {
  @apply text-white text-2xl font-[400] hover:text-color-primary active:text-color-primary transition duration-200;
}
.navbarItem-link.active {
  @apply border-b-2 border-color-primary text-color-primary;
}
/* Mobile Side-bar */

.sidenav {
  @apply fixed h-[100vh] top-0 right-[-100%] bg-black z-10 w-[50vw] lg:hidden;
}

.sidenav.active {
  @apply right-0 w-[70%];
}

.sidenav-menu {
  @apply flex flex-col gap-5 w-full mt-24;
}

.sidenav-menu__item {
  @apply hover:bg-color-primary active:bg-color-primary transition duration-200 hover:text-black active:text-black text-white text-3xl py-3 px-2;
}

.ql-picker-label {
  @apply flex items-center gap-5 relative text-center;
}
.ql-picker-label svg {
  @apply absolute right-0;
}
.ql-editor {
  @apply text-right !important;
}

.input-container {
  @apply border rounded bg-[#F4F4F4]  py-4 px-3    text-2xl   invalid:focus-within:border-b-red-500;
}
.input {
  @apply w-full  outline-none   border-none  bg-transparent placeholder:text-lg rounded;
}

.container {
  width: min(90vw, 90%);
  margin: 0 auto;
}
