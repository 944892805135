.header {
  height: 70vh;
  padding-top: 4rem;
  position: relative;
  background-repeat: no-repeat;
  @include media-prop(background-size, contain, cover, cover);
  background-color: $--color-black;
  @include media-prop(padding-inline, 7rem, 4rem, 1.2rem);
  @include media-prop(
    box-shadow,
    0 0 0 2000px inset rgba(0, 0, 0, 0.4),
    0 0 0 2000px inset rgba(0, 0, 0, 0.7),
    0 0 0 2000px inset rgba(0, 0, 0, 0.8)
  );

  &-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    @include media-prop(max-width, 50%, 100%, 100%);
    text-align: center;

    &__title {
      @extend .gradient-text-primary;
    }
    &__description {
      @extend .typo--l;
      font-family: serif;
      margin-bottom: $--margin-m;
      @extend .gradient-text-secondary;
      @extend .typo--bold;
    }
    &__ifno {
      @extend .typo--m;
      line-height: 1.4;
      color: $--color-gray;
      @extend .typo--sbold;
    }
    &__actions {
      background: linear-gradient(
        to right,
        gold 20%,
        orange 40%,
        gold 60%,
        #333 80%
      );
      // @extend .gradient-text-primary;
      @extend .typo--l;
      @extend .typo--sbold;
      line-height: 1.4;
      background-size: 200% auto;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: shine 5s linear infinite;
      &--cta {
        margin-top: $--paddding-m;
      }
    }
  }
}
@keyframes shine {
  to {
    background-position: 200% center;
  }
}
