.featured {
  flex: 3;
  box-shadow: $--box-shadow;
  -webkit-box-shadow: $--webkit-box-shadow;
  &__top {
    @extend .flex-between;
    padding: $--paddding-s;
    &__title {
      font-size: 1.6rem;
      font-weight: 300;
      color: $--color-white;
    }
    &__icon {
      color: $--color-gray;
      cursor: pointer;
    }
  }
  &__bottom {
    @extend .flex-center;
    flex-direction: column;
    padding: $--paddding-m;
    gap: $--paddding-m;
    &__chart {
      width: 10rem;
      height: 10rem;
    }
    &__title {
      font-weight: 500;
      color: $--color-white;
    }
    &__amount {
      font-size: 3rem;
      color: $--color-gray;
    }
    &__description {
      font-size: 1.2rem;
      font-weight: 300;
      color: $--color-white;
      text-align: center;
    }
    &__summary {
      @extend .flex-between;
      width: 100%;
      &__item {
        text-align: center;
        &__title {
          font-size: 1.4rem;
          color: $--color-white;
        }
        &__result {
          display: flex;
          align-items: center;
          margin-top: $--margin-s;
          font-size: 1.4rem;
          &.postive {
            color: green;
          }
          &.negative {
            color: red;
          }
        }
      }
    }
  }
}
