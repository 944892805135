.footer {
  background-color: $--color-black-light;
  box-shadow: $--box-shadow;
  -webkit-box-shadow: $--webkit-box-shadow;
  &-center {
    padding-block: $--margin-s;
    @extend .flex-center;
    flex-direction: column;
    &-iframe {
      width: 200px;
      height: 100px;
      margin-top: $--margin-m;
    }
    &__logo {
      margin-bottom: $--margin-m;
      max-width: 14rem;
      &-img {
        object-fit: contain;
      }
    }
  }
  &-privacy {
    color: $--color-gray;
    @extend .typo--s;
    margin-right: auto;
    padding-inline: $--paddding-s;
  }

  &-bottom {
    text-align: center;
    padding-block: $--paddding-xs1;
    background-color: $--color-black;

    &__text {
      color: $--color-white;
      font-size: 1.4rem;
      @extend .typo--light;
    }
  }
}
